/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import PasswordValidator from 'password-validator';
import jwtDecode from '../../utils/jwtDecode';
import { setAccessToken } from '../user-service'; // eslint-disable-line import/no-cycle
import sendRumError from '../../utils/datadogRum';
import {
  AdminGrant, AlertType, AuthResult, DecodedUserServiceToken, SessionPropType, UserGrant,
} from './types';
import GlobalConfig from '../../app/config';
import { oAuthLocalStoreKeyPrefix } from '../../utils/constants';

const schema = new PasswordValidator();
/* eslint-disable newline-per-chained-call */
schema
  .is().min(8, 'Should have a minimum length of 8 characters')
  .is().max(100, 'Should have a maximum length of 100 characters')
  .has().uppercase(1, 'Should have a minimum of 1 uppercase letter')
  .has().lowercase(1, 'Should have a minimum of 1 lowercase letter')
  .has().digits(1, 'Should have a minimum of 1 digit');

const getPool = () => {
  const res = new CognitoUserPool({
    UserPoolId: GlobalConfig.config.COGNITO_POOL_ID,
    ClientId: GlobalConfig.config.COGNITO_CLIENT_ID,
  });
  return res;
};

const getUser = () => {
  const userPool = getPool();
  return userPool.getCurrentUser();
};

const isCognitoLoggedin = () => getUser() != null;

const handleSuccessfulLogin = async (token: string) => {
  localStorage.setItem('cognito_jwt', token);
  await setAccessToken();
};

const login = (email: string, password: string) => new Promise<AuthResult>((resolve, reject) => {
  const userPool = getPool();

  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  let result: AuthResult;
  user.authenticateUser(authDetails, {
    onSuccess: (data: any) => {
      handleSuccessfulLogin(data.getAccessToken().getJwtToken())
        .then(() => resolve({
          status: 'SUCCESS',
          body: undefined,
        }));
    },
    onFailure: (error: Error) => {
      result = {
        status: 'FAILURE',
        body: error,
      };
      reject(result);
    },
    newPasswordRequired: (userAttributes) => {
      result = {
        status: 'NEWPASSWORDREQUIRED',
        body: {
          user,
          userAttributes,
        },
      };
      resolve(result);
    },
  });
});

const logout = async () => {
  const user = getUser();
  if (user) {
    await new Promise<void>((resolve) => {
      user.signOut(() => {
        resolve();
      });
    });
  }

  const keysToBeRemoved = [
    'user_service_token',
    'persist:root',
    'subscriptionStore',
  ];

  Object.entries(localStorage)
    .filter(([key]) => (
      // clear sp-api data from store
      key.startsWith(oAuthLocalStoreKeyPrefix)
      // forces local storage clear in the event that user is invalid
      || key.toLowerCase().startsWith('cognito')
      || keysToBeRemoved.includes(key)
    ))
    .forEach(([key]) => localStorage.removeItem(key));

  window.dispatchEvent(new Event('user_service_token_change'));
  window.dispatchEvent(new Event('user_logout'));
};

const changePassword = (password: string, newPassword: string) => new Promise((resolve, reject) => {
  const user = getUser();
  if (!user) {
    reject(new Error('User could not be retrieved'));
  }
  user?.getSession((error: Error | undefined) => {
    if (error) {
      reject(error);
    }
    user?.changePassword(password, newPassword, (changeError, status) => {
      if (status === 'SUCCESS') {
        resolve({
          status,
          body: status,
        });
      } else {
        reject(changeError);
      }
    });
  });
});

const changeRequiredPassword = (
  session: SessionPropType,
  password: string,
) => new Promise<AuthResult>((resolve, reject) => {
  const { user, userAttributes } = session;
  let result: AuthResult;
  delete userAttributes.email_verified;
  delete userAttributes.email;
  (user as CognitoUser).completeNewPasswordChallenge(password, userAttributes, {
    onFailure: (error: Error) => {
      result = {
        status: 'FAILURE',
        body: error,
      };
      reject(result);
    },
    onSuccess: async (data: any) => {
      await handleSuccessfulLogin(data.getAccessToken().getJwtToken());
      result = {
        status: 'SUCCESS',
        body: undefined,
      };
      resolve(result);
    },
  });
});

export const getTokenValues = (): DecodedUserServiceToken | null => {
  try {
    if (localStorage.getItem('user_service_token') == null) {
      throw Error('no user_service_token set');
    }
    const tokenDump = localStorage.getItem('user_service_token') as string;
    const decodedToken = jwtDecode<DecodedUserServiceToken>(tokenDump);

    return decodedToken;
  } catch (error) {
    if (!(error instanceof Error)
      || error.message !== 'no user_service_token set'
    ) {
      sendRumError(error);
    }
    return null;
  }
};

const checkUserServiceToken = () => {
  const tokenValues = getTokenValues();

  // if null: return false
  if (!tokenValues) {
    return false;
  }

  // now check for expiration
  // both values measured in Epoch
  return (tokenValues.exp > (new Date()).getTime() / 1000);
};

const clearToken = () => {
  localStorage.removeItem('user_service_token');
};

export const refreshSession = () => new Promise<AuthResult>((resolve, reject) => {
  const user = getUser();
  try {
    if (!user) {
      throw new Error('Attempted to refesh a null user');
    }
    user?.getSession((err: Error | null, session: CognitoUserSession) => {
      if (!err) {
        const refreshToken = session.getRefreshToken();
        if (session.isValid()) {
          handleSuccessfulLogin(
            session.getAccessToken().getJwtToken() as unknown as string,
          ).then(() => resolve({
            status: 'SUCCESS',
            body: undefined,
          }));
        } else {
          user.refreshSession(
            refreshToken,
            (refreshError: Error, newSession: CognitoUserSession) => {
              if (!refreshError) {
                return handleSuccessfulLogin(
                  newSession.getAccessToken().getJwtToken() as unknown as string,
                ).then(() => resolve({
                  status: 'SUCCESS',
                  body: undefined,
                }));
              }

              return logout().then(() => {
                reject(refreshError);
              });
            },
          );
        }
      } else {
        throw err;
      }
    });
  } catch (error) {
    sendRumError(error);
    logout();
    reject(error);
  }
});

const validateEmail = (email: string) => email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

const validatePassword = (password: string) => schema.validate(password, { details: true });

function hasGrants(currentOrgId: number, requestedGrants: UserGrant[] = ['MEMBER'], adminGrants: AdminGrant[] = ['MEMBER']) {
  try {
    const orgString = String(currentOrgId);
    const user = getTokenValues();

    // 11/07/2023 - don't auto grant ORG_ADMIN / ORG_OWNER to all views.
    let grantArray = requestedGrants;
    if (requestedGrants.length > 0 && requestedGrants.includes('ORG_OWNER')) {
      grantArray = ['ORG_OWNER'];
    } else if (requestedGrants.length > 0) {
      grantArray = requestedGrants.concat(['ORG_OWNER', 'ORG_ADMIN']);
    }

    const isAllowed: boolean = user?.grants[orgString]?.some(
      (value) => grantArray.includes(value),
    ) ?? false;

    if (isAllowed) {
      return true;
    }

    const zeroMembership = (user?.grants[0] || []) as unknown as AdminGrant[];

    const zeroMembershipTestSet = [...adminGrants, 'ADMIN'];
    const hasAdminOveride = zeroMembership.some(
      (grant) => zeroMembershipTestSet.includes(grant),
    );

    return hasAdminOveride;
  } catch (error) {
    sendRumError(error);
    return false;
  }
}

const passwordCriteria: string[] = [
  'Should have a minimum length of 8 characters',
  'Should have a maximum length of 100 characters',
  'Should have a minimum of 1 uppercase letter',
  'Should have a minimum of 1 lowercase letter',
  'Should have a minimum of 1 digit',
];

const alerts: { [key: string]: AlertType } = {
  passwordMisMatchAlert: {
    msg: 'Your New Password and Confirm Password do not match.',
    var: 'danger',
  },
  passwordInvalid: {
    msg: 'Your password does not follow the requirements listed above.',
    var: 'danger',
  },
  failure: {
    msg: 'Failed to change your password. Please refresh and try again.',
    var: 'danger',
  },
  passwordSuccess: {
    msg: 'Your password has been successfully updated.',
    var: 'success',
  },
};
export {
  changePassword,
  changeRequiredPassword,
  checkUserServiceToken,
  clearToken,
  getPool,
  getUser,
  hasGrants,
  isCognitoLoggedin,
  login,
  logout,
  validatePassword,
  validateEmail,
  passwordCriteria,
  alerts,
};

/* istanbul ignore file */

import React, { SVGProps } from 'react';

// SVG from https://www.svgrepo.com/svg/13223/inverted-triangle
function TriangleDown({
  fill = '#FFFFFF',
  width = '16',
  height = '16',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      {...props}
      viewBox="0 0 27 27"
    >
      <g>
        <path d="M28.678,5.798L14.713,23.499c-0.16,0.201-0.495,0.201-0.658,0L0.088,5.798C-0.009,5.669-0.027,5.501,0.04,5.353    C0.111,5.209,0.26,5.12,0.414,5.12H28.35c0.16,0,0.31,0.089,0.378,0.233C28.798,5.501,28.776,5.669,28.678,5.798z" />
      </g>
    </svg>
  );
}
export default TriangleDown;

import axios, { AxiosInstance } from 'axios';
import { headers } from '..';
import { ValidationError } from '../../utils/webRequests.type';
import {
  IssueMultipleConfirmType,
  IssueMultipleCreateType,
  IssueType,
  IssueUpdateType,
} from './types';
import useAuthenticationInterceptors from '../auth/useAuthenticationInterceptors';

let maintenanceService: AxiosInstance;

const fileHeaders = (orgID: number) => ({
  ...headers(orgID),
  'Content-Type': 'multipart/form-data',
});

export const initializeMaintenanceController = (serviceUrl: string) => {
  maintenanceService = axios.create({
    baseURL: serviceUrl,
  });
  useAuthenticationInterceptors(maintenanceService);
};

export const fetchIssues = async (orgId: number, now:Date = (new Date()), daysBack = 365, status:('open' | 'closed' | null) = null) => {
  // request all notifications recorded in the past 365 days.
  now.setDate(now.getDate() - daysBack);

  const params = {
    org_id: orgId,
    recorded_timestamp_from: now.toISOString(),
    status,
  };

  return maintenanceService.get<IssueType[] | ValidationError>('/issues/', { params, headers: headers(orgId) });
};

export const fetchIssue = async (orgId: number, id: string) => maintenanceService.get(`/issue/${id}/`, { headers: headers(orgId) });

export const postIssue = async (orgId: number, payload: IssueType) => maintenanceService.post<IssueType>('/issue/', payload, { headers: headers(orgId) });

export const patchIssue = async (id: string, payload: IssueUpdateType) => maintenanceService.patch<IssueType>(`/issue/${id}`, payload, { headers: headers(0) });

export const postAttachment = async (orgId: number, id: string, payload: FormData) => maintenanceService.post(`/issue/${id}/attachments/`, payload, { headers: fileHeaders(orgId) });

export const listFiles = async (orgId: number, id: string) => {
  const { data } = await fetchIssue(orgId, id);
  return data?.details?.file_list;
};

export const showStatus = async (orgId: number, id: string) => {
  const { data } = await fetchIssue(orgId, id);
  return data?.status;
};

// bulk version of postIssue.  used because spamming postIssue bombs maintenance-service
export const postIssues = async (
  payload: IssueMultipleCreateType,
) => maintenanceService.post('/issues/', payload, { headers: headers(0) });

// bulk version of patchIssue.  used because spamming patchIssue bombs maintenance-service
export const patchIssuesConfirm = async (
  payload: IssueMultipleConfirmType,
) => maintenanceService.patch('/issues/confirm', payload, { headers: headers(0) });

import React, { CSSProperties } from 'react';
import Alert from 'react-bootstrap/Alert';

export type AlertTypes =
  'primary' |
  'secondary' |
  'success' |
  'danger' |
  'warning' |
  'info' |
  'dark' |
  'light';

export type SnackBarProps = {
  show: boolean;
  alertType?: AlertTypes;
  onClose?(): void;
  header: string;
  'data-testid'?: string;
  alertText: React.ReactNode;
  style: CSSProperties;
};

function SnackBar({
  show,
  alertType,
  onClose,
  header,
  alertText,
  style,
  'data-testid': dataTestId,
}: SnackBarProps) {
  return (
    <Alert
      style={style}
      variant={alertType}
      onClose={onClose}
      dismissible
      data-testid={dataTestId}
      show={show}
      className={show ? '' : 'd-none'}
    >
      <Alert.Heading>{header}</Alert.Heading>
      <div>{alertText}</div>
    </Alert>
  );
}

SnackBar.defaultProps = {
  alertType: 'danger',
  onClose: () => {},
  'data-testid': 'SnackBar',
};
export default SnackBar;

import React from 'react';
import PropTypes from 'prop-types';
import {
  validatePassword,
  passwordCriteria,
} from '../../controllers/auth';

const propTypes = {
  proposedPassword: PropTypes.string.isRequired,
  confirmedPassword: PropTypes.string,
};

type PasswordCriteriaProps = PropTypes.InferProps<typeof propTypes>;
function PasswordCriteria(props: PasswordCriteriaProps) {
  const { proposedPassword, confirmedPassword } = props;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const passwordValidation = validatePassword(proposedPassword) as any[];
  const shortPasswordDetails = passwordValidation.map((detail) => detail.message);
  let customPasswordCriteria = [...passwordCriteria];
  if (confirmedPassword !== undefined) {
    if (proposedPassword !== confirmedPassword) {
      shortPasswordDetails.push('Should match confirm password');
    }
    customPasswordCriteria = ['Should match confirm password', ...passwordCriteria];
  }
  return (
    <ul>
      {customPasswordCriteria.map((criteria) => {
        if (shortPasswordDetails.includes(criteria)) {
          return <li key={criteria}>{criteria}</li>;
        }
        return <li key={criteria}><strong>{criteria}</strong></li>;
      })}
    </ul>
  );
}

PasswordCriteria.propTypes = propTypes;

PasswordCriteria.defaultProps = {
  confirmedPassword: undefined,
};

export default PasswordCriteria;

import { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { isCognitoLoggedin, checkUserServiceToken, refreshSession } from '.';

const useAuthenticationInterceptors = (
  service: AxiosInstance,
) => {
  service.interceptors.request.use(async (config) => {
    if (config.headers.Authorization === undefined) {
      return config;
    } if (!isCognitoLoggedin()) {
      datadogRum.addAction('User is not logged in');
      // throw Error('User is not logged in');
    }
    if (!checkUserServiceToken()) {
      const newConfig = await refreshSession()
        .then(() => ({
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${localStorage.getItem('user_service_token')}`,
          } as AxiosRequestHeaders,
        }))
        .catch(Promise.reject.bind(Promise));
      return newConfig;
    }
    return config;
  }, (error) => Promise.reject(error));
};

export default useAuthenticationInterceptors;

import { CollectionType } from '../../controllers/subscription-service/types';
import { CollectionTypeTextMap } from '../../utils/subscriptionFlavorText';

/* eslint-disable max-len */
export type NotificationTemplateType = {
  label: string,
  template: string,
};

const messageTemplates: NotificationTemplateType[] = [
  {
    label: 'Retailer Site Change',
    template: 'Due to <retailer_name> changing their site structure, <collection_type> data is delayed.',
  },
  {
    label: 'Retailer Significant Site Change',
    template: 'Due to <retailer_name> significantly changing their site structure, <collection_type> data is delayed.',
  },
  {
    label: 'Retailer Site Outage',
    template: 'Due to <retailer_name> being offline, data is delayed.',
  },
  {
    label: 'Retailer Report Period Unavailable',
    template: '<retailer_name> report source data is not available. <feature_name> data appearing in our dashboards is behind as a result.',
  },
  {
    label: 'Retailer Report Data Unreliable',
    template: 'Material anomalies in <retailer_name> report source data have been detected. Data in our dashboards may be inaccurate as a result.',
  },
  {
    label: 'QuickSight Issue',
    template: 'Issues with backend services have resulted in the temporary outage of <feature_name> dashboards.',
  },
];

const collectionTypeList: { type: CollectionType, english: string }[] = (
  Object.entries(CollectionTypeTextMap).map(([type, english]) => ({
    type: type as CollectionType,
    english,
  }))
);

const collectionTypeToEnglishMap = (k: CollectionType) => (
  collectionTypeList.filter(({ type }) => type === k).length
    ? collectionTypeList.filter(({ type }) => type === k)[0].english
    : k
);

const englishToCollectionTypeMap = (k: string) => (
  collectionTypeList.filter(({ english }) => english === k).length
    ? collectionTypeList.filter(({ english }) => english === k)[0].type
    : k
);

export {
  messageTemplates,
  collectionTypeToEnglishMap,
  englishToCollectionTypeMap,
};

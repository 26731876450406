// eslint-disable-next-line @typescript-eslint/no-explicit-any
const partialMatch = (partialObj: any, fullObj: any) => (
  Object.keys(partialObj).every((key) => partialObj[key] === fullObj[key])
);
export const hasCommonItems = (
  a: string[],
  b: string[],
): boolean => a.some((item) => b.includes(item));

export default partialMatch;

/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  OverlayTrigger,
  Table,
} from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { startCase } from 'lodash';

import './NotificationAdmin.css';
import LoadingPage from '../LoadingPage/LoadingPage';
import PageHeader from '../../components/PageHeader/PageHeader';
import SearchInput from '../../components/SearchInput/SearchInput';
import LeftFilterSelect from '../../components/LeftFilterSelect/LeftFilterSelect';
import {
  fetchIssues,
  patchIssuesConfirm,
} from '../../controllers/maintenance-service';
import { AsyncState } from '../../utils/webRequests.type';
import { Info } from '../../icons';
import { filterIssues, generateIssueHash, sortIssuesByFieldGenerator } from './utilities';
import renderOrgListTooltip from '../../components/renderOrgListTooltip/renderOrgListTooltip';
import RenderTableHeaderWithArrow from '../../components/renderTableHeaderWithArrow/renderTableHeaderWithArrow';
import { DisplayedIssue, IssueDetailType, IssueMultipleConfirmType } from '../../controllers/maintenance-service/types';
import ErrorPage from '../ErrorPage/ErrorPage';

const formatIssueDate = (timestamp: string | undefined) => {
  if (timestamp === undefined) {
    return 'Not Available';
  }

  const dateTimeArray = new Date(timestamp).toISOString().split('T');
  dateTimeArray[1] = dateTimeArray[1].slice(0, 8);
  return dateTimeArray.join(' ');
};

const tableHeaders = [
  {
    field: 'type',
    label: 'Type',
  },
  {
    field: 'org_id_list',
    label: 'Orgs',
  },
  {
    field: 'message',
    label: 'Message',
  },
  {
    field: 'tags',
    label: 'Tags',
  },
  {
    field: 'recorded_timestamp',
    label: 'Date Created (UTC)',
  },
];

export default function NotificationAdmin() {
  // state for issues
  const [issueLoadStatus, setIssueLoadStatus] = useState<AsyncState>('uninitialized');
  const [issues, setIssues] = useState<DisplayedIssue[]>([]);

  // states to help with filtering (options)
  const [issuesRetailerList, setIssuesRetailerList] = useState<string[]>([]);
  const [issuesCollectionTypeList, setIssuesCollectionTypeList] = useState<string[]>([]);
  const [issuesFeatureList, setIssuesFeatureList] = useState<string[]>([]);

  // states of active filters
  const [notificationQueryFilter, setNotificationQueryFilter] = useState<string>('');
  const [retailerFilter, setRetailerFilter] = useState<string[]>([]);
  const [collectionTypeFilter, setCollectionTypeFilter] = useState<string[]>([]);
  const [featureListFilter, setFeatureFilter] = useState<string[]>([]);

  // state of filtered issues
  const [filteredIssues, setFilteredIssues] = useState<DisplayedIssue[]>([]);

  // helpers for the table
  const [notificationSelectAll, setNotificationSelectAll] = useState<boolean>(false);
  const [notificationNoneSelected, setNotificationNoneSelected] = useState<boolean>(true);
  const [sortField, setSortField] = useState<Extract<keyof DisplayedIssue, string>>('org_id_list');
  const [sortASC, setSortASC] = useState<boolean>(true);

  const navigate = useNavigate();

  // loads the full "open" issues list from maintenance-service,
  // groups them by their key
  //   (message + type + tags),
  // and loads filtering options.
  //
  // multiple tags are permitted in a field (semicolon separated)
  //   and are broken apart for filtering options.
  const loadIssues = async () => {
    setIssueLoadStatus('loading');
    try {
      const { data: issueData } = await fetchIssues(0, new Date(), 365, 'open');
      if (!Array.isArray(issueData)
        || issueData.some((v) => !(v instanceof Object))
      ) {
        throw new Error('error loading issues');
      }
      const issueDict: { [key: string]: DisplayedIssue } = {};
      const retailerDict: { [key: string]: string } = {};
      const collectionDict: { [key: string]: string } = {};
      const featureDict: { [key: string]: string } = {};

      const allowedIssueTypes: IssueDetailType[] = ['collection_outage', 'feature_outage', 'retailer_outage'];

      issueData
        .filter(
          (issue) => allowedIssueTypes.includes(issue.type),
        )
        .forEach(
          (issue) => {
            const tags: string[] = [];
            const details = JSON.parse(JSON.stringify(issue.details ?? {}));

            // populate tags and filter options
            if (details) {
              if ('retailer_name' in details) {
                const retailerNameList = details.retailer_name.split('; ');
                tags.push(...retailerNameList);
                retailerNameList.forEach(
                  (retailerName: string) => {
                    retailerDict[retailerName] = retailerName;
                  },
                );
              }
              if ('collection_type' in details) {
                const localCollectionTypeList = details.collection_type.split('; ');
                tags.push(...localCollectionTypeList);
                localCollectionTypeList.forEach(
                  (collectionType: string) => {
                    collectionDict[collectionType] = collectionType;
                  },
                );
              }
              if ('feature_name' in details) {
                const featureNameList = details.feature_name.split('; ');
                tags.push(...featureNameList);
                featureNameList.forEach(
                  (featureName: string) => {
                    featureDict[featureName] = featureName;
                  },
                );
              }
            }

            // group by key
            const key = generateIssueHash(
              issue.message,
              issue.type,
              tags,
            );
            if (!(key in issueDict)) {
              issueDict[key] = {
                ...issue,
                key,
                message: issue.message,
                type: issue.type,
                tags,
                org_id_list: [],
                id_list: [],
                is_selected: false,
                severity: issue.severity,
                details: issue.details,
              };
            }

            // helps with the toolTip display
            if (issue.org_id !== undefined) {
              issueDict[key].org_id_list.push(issue.org_id);
            }

            // helps when Marking as Resolved
            if (issue.id !== undefined) {
              issueDict[key].id_list.push(issue.id);
            }
          },
        );
      const issueList: DisplayedIssue[] = Object.keys(issueDict)
        .map(
          (key) => issueDict[key],
        );
      setIssues(issueList);
      setIssuesRetailerList(Object.keys(retailerDict));
      setIssuesCollectionTypeList(Object.keys(collectionDict));
      setIssuesFeatureList(Object.keys(featureDict));
      setIssueLoadStatus('completed');
    } catch {
      setIssueLoadStatus('failed');
    }
  };

  // if there is a change in the filter state,
  // then re-build the filteredIssues list.
  useEffect(() => {
    setFilteredIssues(
      filterIssues(
        issues,
        [
          ...retailerFilter,
          ...collectionTypeFilter,
          ...featureListFilter,
        ],
        notificationQueryFilter,
      ),
    );
    setNotificationSelectAll(false);
    setNotificationNoneSelected(true);
  }, [
    issues,
    notificationQueryFilter,
    retailerFilter,
    collectionTypeFilter,
    featureListFilter,
  ]);

  useEffect(() => {
    if (issueLoadStatus === 'failed') {
      navigate('/error');
    }
  }, [issueLoadStatus]);

  // load data
  useEffect(() => {
    loadIssues();
  }, []);

  const setSortCriteria = (f: keyof DisplayedIssue) => {
    if (sortField === f) {
      setSortASC(!sortASC);
    } else {
      setSortField(f);
      setSortASC(true);
    }
  };

  const selectAllIssuesHandle = (enabled: boolean) => {
    const temp = filteredIssues.map(
      (issue) => {
        const newIssue = { ...issue };
        newIssue.is_selected = enabled;
        return newIssue;
      },
    );
    setFilteredIssues(temp);
    setNotificationSelectAll(enabled);
    setNotificationNoneSelected(!enabled);
  };

  const selectOneIssueHandle = (
    enabled: boolean,
    issue_key: string,
  ) => {
    let isAllTrue = true;
    let isAllFalse = true;
    const temp = filteredIssues.map(
      (issue) => {
        const newIssue = { ...issue };
        if (newIssue.key === issue_key) {
          newIssue.is_selected = enabled;
        }
        if (newIssue.is_selected) {
          isAllFalse = false;
        } else {
          isAllTrue = false;
        }
        return newIssue;
      },
    );
    setFilteredIssues(temp);
    setNotificationSelectAll(isAllTrue);
    setNotificationNoneSelected(isAllFalse);
  };

  // when the "Mark as Resolved" is clicked
  // .. instructing the backend to perform a soft-delete.
  const deleteMessageHandler = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const openPromises: Promise<AxiosResponse>[] = [];

    // for each GROUP of issues, send a single request to the back end.
    filteredIssues
      .filter(
        (issue) => issue.is_selected,
      )
      .forEach(
        (issue) => {
          const payload: IssueMultipleConfirmType = {
            issue_ids: issue.id_list,
          };
          const newPromise = patchIssuesConfirm(payload);
          openPromises.push(newPromise);
        },
      );
    // trigger a refresh.
    Promise.allSettled(openPromises)
      .then(() => {
        loadIssues();
      });
  };

  switch (issueLoadStatus) {
    case 'uninitialized':
    case 'loading':
      return <LoadingPage />;
    case 'completed':
      return (
        <div
          id="notification-admin-page"
          className="page h-100"
        >
          <Form
            className="h-100 col-12"
          >
            <div className="PageContent row">
              <PageHeader
                title="Manage Notifications"
                returnText="Back to Home"
                returnLink="/"
              />
              <div className="col-3 h-100 info-pane">
                <h6>Select Tags:</h6>

                <div className="pane">
                  <LeftFilterSelect
                    label="Retailer"
                    listLoadStatus={issueLoadStatus}
                    listData={issuesRetailerList}
                    selectedData={retailerFilter}
                    setSelectedData={setRetailerFilter}
                  />
                </div>

                <div className="pane">
                  <LeftFilterSelect
                    label="Collection Type"
                    listLoadStatus={issueLoadStatus}
                    listData={issuesCollectionTypeList}
                    selectedData={collectionTypeFilter}
                    setSelectedData={setCollectionTypeFilter}
                  />
                </div>

                <div className="pane">
                  <LeftFilterSelect
                    label="Feature"
                    listLoadStatus={issueLoadStatus}
                    listData={issuesFeatureList}
                    selectedData={featureListFilter}
                    setSelectedData={setFeatureFilter}
                  />
                </div>
              </div>
              <div
                className="col-9 h-100 container pull-right"
              >
                <h6>Review Notifications:</h6>
                <div className="row mb-3">
                  <div className="col-9">
                    <SearchInput
                      className="notification-search-input"
                      data-testid="notification-search-input"
                      placeholder="Search notifications"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setNotificationQueryFilter(e.currentTarget.value);
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <Button
                      variant="primary"
                      data-testid="notification-mark-as-resolved"
                      className="w-100 action-button"
                      disabled={notificationNoneSelected}
                      onClick={deleteMessageHandler}
                    >
                      Mark as Resolved
                    </Button>
                  </div>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          key="notification-select-all"
                          id="notification-select-all"
                          data-testid="notification-select-all"
                          onChange={
                            (event) => selectAllIssuesHandle(event.currentTarget.checked)
                          }
                          checked={notificationSelectAll}
                        />
                      </th>
                      {
                        tableHeaders.map(
                          (th) => (
                            <RenderTableHeaderWithArrow
                              field={th.field}
                              key={`sort-${th.field}`}
                              sortField={sortField}
                              sortASC={sortASC}
                              onClickHandler={() => setSortCriteria(
                                th.field as keyof DisplayedIssue,
                              )}
                            >
                              {th.label}
                            </RenderTableHeaderWithArrow>
                          ),
                        )
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filteredIssues
                        .sort(sortIssuesByFieldGenerator(sortField, sortASC))
                        .map(
                          (issue) => (
                            <tr
                              key={`issue-${issue.key}`}
                            >
                              <td>
                                <Form.Check
                                  key={`issue-select-${issue.key}`}
                                  id={`issue-select-${issue.key}`}
                                  data-testid={`issue-select-${issue.key}`}
                                  onChange={(event) => selectOneIssueHandle(
                                    event.currentTarget.checked,
                                    issue.key ?? '',
                                  )}
                                  checked={issue.is_selected}
                                />
                              </td>
                              <td>{startCase(issue.type.replaceAll('_', ' '))}</td>
                              <td className="org-list-overlay">
                                {String(issue.org_id_list.length)}
                                &nbsp;
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={(props) => renderOrgListTooltip(
                                    props,
                                    issue.org_id_list,
                                  )}
                                >
                                  <span className="text-gizmo-grey">
                                    <Info />
                                  </span>
                                </OverlayTrigger>
                              </td>
                              <td>{issue.message}</td>
                              <td>{issue.tags?.join('; ')}</td>
                              <td className="text-nowrap">
                                {formatIssueDate(issue.recorded_timestamp)}
                              </td>
                            </tr>
                          ),
                        )
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </Form>
        </div>
      );
    default:
      return <ErrorPage />;
  }
}
